import React from "react";
import { Container } from "react-bootstrap";
import getImagePath from "helpers/get-image-path";
import Richtext from "components/rich-text";

import {
  floatingHero, floatingHeroTitle,
  floatingHeroDescription, floatingHeroImageMobile,
  floatingHeroImage, floatingHeroParagraph,
  floatingHeroParagraphMobile
} from "./style.mod.scss";

const FloatingHero = ({ data }) => {
  const { hero, content, title } = data;

  return (
    <div className={floatingHero}>
      <Container>
        <div className={floatingHeroDescription}>
          <div className={floatingHeroTitle}>
            <h2>{title}</h2>
            <img src={getImagePath(hero)} className={floatingHeroImage + " " + floatingHeroImageMobile} />
          </div>
          <div className={floatingHeroParagraph}>
            <Richtext blocks={content} />
          </div>
        </div>
      </Container>
      <div className={floatingHeroParagraphMobile}>
        <Container>
          <Richtext blocks={content} />
        </Container>
      </div>
      <img src={getImagePath(hero)} className={floatingHeroImage} />
    </div>
  );
};

export default FloatingHero;
